.texto-xl {
    font-size: 34px !important;
}

.texto-grande-x {
    font-size: 24px !important;
}

.texto-grande {
    font-size: 18px !important;
}
  
.texto-lg {
    font-size: 13px !important;
}

.texto-mediano {
    font-size: 12px !important;
}

.texto-small {
    font-size: 11px !important;
}

.texto-xs {
    font-size: 10px !important;
}

.boton-personalizado {
    background-color: hsl(122, 40%, 60%); /* Ajusta los valores según tus preferencias */
    border-color: hsl(122, 40%, 70%);
    color: #28a745; /* Color de texto para mantener la legibilidad */
  }
  
  /* Estilos para el hover */
  .boton-personalizado:hover {
    background-color: hsl(122, 40%, 50%);
    border-color: hsl(122, 40%, 60%);
  }