
.bodysidebar {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

.sidebar {
    position: fixed;
    left: 0;
    top: 56px;
    height: 100%;
    width: 68px; /*ancho*/
    background: #F0F1F0;
    padding: 6px 14px;
    z-index: 10;
    transition: all 0.5s ease;
    /* display: none; */
    display: flex;
    flex-direction: column;
}

.sidebar.open {
    width: 220px;
}

.sidebar .logo-details {
    height: 60px;
    display: flex;
    align-items: center;
    position: relative;
}

.sidebar .logo-details .icon {
    opacity: 0;
    transition: all 0.5s ease;
}

.sidebar .logo-details .logo_name {
    color: #4d4d4d;
    font-size: 20px;
    font-weight: 600;
    opacity: 0;
    transition: all 0.5s ease;
}

.sidebar.open .logo-details .icon,
.sidebar.open .logo-details .logo_name {
    opacity: 1;
}

.sidebar .logo-details #btn {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-size: 22px;
    transition: all 0.4s ease;
    font-size: 23px;
    text-align: center;
    cursor: pointer;
    transition: all 0.5s ease;
}

.sidebar.open .logo-details #btn {
    text-align: right;
}

.sidebar i {
    color: #4d4d4d;
    height: 60px;
    min-width: 38px; 
    font-size: 28px;
    text-align: center;
    line-height: 60px;
}

.sidebar .nav-list {
    margin-top: 20px;
    margin-bottom:60px ;
    overflow-y: auto;
    padding-left: 0rem !important;
}

.sidebar .nav-list::-webkit-scrollbar {
    background-color: transparent; /* Color de fondo de la barra de desplazamiento */
    width: 5px; /* Ancho del pulgar */
}

.sidebar .nav-list::-webkit-scrollbar-thumb {
    background-color: rgb(255, 255, 255, 0.1);
    border-radius: 50px;
}

.sidebar .nav-list::-webkit-scrollbar-thumb:hover {
    background-color: rgb(204, 204, 204, 0.80); /* Color del pulgar de la barra de desplazamiento al pasar el cursor */
}

.sidebar .nav-list::-webkit-scrollbar-track {
    background-color: transparent; /* Color de fondo de la pista de la barra de desplazamiento */
}

.sidebar li {
    position: relative;
    margin: 8px 0;
    list-style: none;
}

.sidebar li .tooltip {
    position: absolute;
    top: -20px;/* -20px */
    left: calc(100% + 15px); /* calc(100% + 15px) */
    z-index: 99;
    background: rgb(245, 245, 245);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    padding: 6px 12px;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 500;
    opacity: 0;
    white-space: nowrap;
    pointer-events: none;
    transition: 0s;
    color:rgb(48, 48, 48);
}

.sidebar a:hover .tooltip {
    opacity: 1;
    pointer-events: auto;
    transition: all 0.4s ease;
    top: 50%;
    transform: translateY(-50%);
}

.sidebar.open li .tooltip {
    display: none;
}

.sidebar input {
    font-size: 15px;
    color: #4d4d4d;
    font-weight: 400;
    outline: none;
    height: 50px;
    width: 100%;
    width: 50px;
    border: none;
    border-radius: 12px;
    transition: all 0.5s ease;
    background: #dcdddc;
}

.sidebar.open input {
    padding: 0 20px 0 50px;
    width: 100%;
}

.sidebar li a {
    display: flex;
    height: 100%;
    width: 100%;
    border-radius: 12px;
    align-items: center;
    text-decoration: none;
    transition: all 0.4s ease;
    background: rgb(235, 235, 235);
}

.sidebar li a:hover {
    background: rgb(224, 224, 224);
}

.sidebar li a .links_name {
    color: #4d4d4d;
    font-size: 15px;
    font-weight: 400;
    white-space: nowrap;
    opacity: 0;
    pointer-events: none;
    transition: 0.4s;
}

.sidebar.open li a .links_name {
    opacity: 1;
    pointer-events: auto;
}

.sidebar li a:hover .links_name,
.sidebar li a:hover i {
    transition: all 0.5s ease;
    color: #222323;
}

.sidebar li i {
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    border-radius: 12px;
}

.sidebar li.profile {
    display: flex;
    position: fixed;
    height: 60px;
    width: 68px;
    left: 0;
    bottom: -8px;
    padding: 10px 14px;
    background: rgb(224, 224, 224);
    transition: all 0.5s ease;
    overflow: hidden;
}

.sidebar.open li.profile {
    width: 220px;
}

.sidebar li .profile-details {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}

.sidebar li img {
    height: 45px;
    width: 45px;
    object-fit: cover;
    border-radius: 6px;
    margin-right: 10px;
}

.sidebar li.profile .name,
.sidebar li.profile .job {
    font-size: 15px;
    font-weight: 400;
    color: #4d4d4d;
    white-space: nowrap;
}

.sidebar li.profile .job {
    font-size: 12px;
}

.sidebar .profile #log_out {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background: rgb(224, 224, 224);
    width: 100%;
    height: 60px;
    line-height: 60px;
    border-radius: 0px;
    transition: all 0.5s ease;
}

.sidebar.open .profile a #log_out {
    width: 65px;
    background: none;
    font-size: 20px;
}
.fondowhite {
    margin-left: 20px;
    background-color: transparent !important;
}

.fondowhite #log_out:hover {
    background: rgba(208, 208, 208, 0.935) !important;
    font-weight: 800;
}

.home-section {
    position: relative;
    background: #FFF;/*fondo*/
    min-height: 100vh;
    top: 56px;
    left: 70px;
    width: calc(100% - 70px);
    transition: all 0.5s ease;
}

.sidebar.open ~ .home-section {
    left: 220px;
    width: calc(100% - 220px);
}

.home-section .text {
    display: inline-block;
    color: #11101d;
    font-size: 25px;
    font-weight: 500;
    margin: 18px
}

@media (max-width: 420px) {
    .sidebar li .tooltip {
        display: none;
    }
}

.Contents_table {
    width: 100%;
    height: 400px;
    box-shadow: 3px 5px 10px rgba(0, 0, 0, .8);
    border-radius: 5px;
    overflow: hidden;
    background-color: #d7dceb;
}

.container {
    width: 100%;
    height: 85%;
}

/* css para submenu */

.sub-btn{
    margin:0px;
}
.item {
    position: relative;
    cursor: pointer;
}
.sub-menu {
    background: #303031;
    display: none;
}
.sub-menu-open {
    display: block;
    border-radius: 10px;
}

.item a {
    position: relative;
    margin: 8px 0;
    list-style: none;
}

.sub-menu .sub-item {
    background: none;
    color: aliceblue;
    height: 40px;
}
.sub-item:hover {
    margin-left: 0px;
    color: #4d4d4d;
    border-radius: 10px;
}

.dropdown-open {
    position: absolute;
    right: 0;
    margin: 20px;
    margin-right: 0px;
    transition: 0.3s ease;
}
.dropdown-close {
    position: absolute;
    right: 0;
    margin: 20px;
    transition: 0.3s ease;
    display:none;
}
.rotate {
    transform: rotate(90deg);
}

.custom-tooltip-style {
    background-color: transparent !important; /* Fondo transparente */
    color: #fff !important; /* Color del texto */
    border-radius: 4px !important; /* Radio de borde */
}

    .custom-tooltip-style  .tooltip-arrow {
        border-right-color: white !important; /* Color de la flecha del tooltip */
        color: white;
    }

    .custom-tooltip-style .tooltip-inner {
        background-color: white !important; /* Fondo transparente */
        padding: 8px !important; /* Espaciado interno */
        font-size: 14px !important; /* Tamaño de fuente */
        font-weight: bold !important; /* Peso de fuente */
        color: black !important; /* Color del texto */
        border-right-color: white !important; /* Color de la flecha del tooltip */
    }

#btn-open-sidebar {
    margin-top: var(--bs-navbar-brand-padding-y);
    margin-right: var(--bs-navbar-brand-margin-end);
    padding: 10px;
    border-radius: 10px;
    color: #4d4d4d;
    font-size: 22px;
    transition: all 0.4s ease;
    font-size: 23px;
    text-align: center;
    cursor: pointer;
    transition: all 0.5s ease;
}
#btn-open-sidebar:hover{
    background-color: #dfdbdb;
    color: #4d4d4d;
}