/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap'); */
.container-login{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}
.container-login {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    /* background-image: url("../img/img4.png"); */
    /*background: rgba(35, 36, 42, 0.226);*/ /* 23242a */
    background-size: cover;
    background-position: center;
}
.container-login::before {
    content: "";
    position: absolute;
    top: 0px; /* 75px */
    left: 0;
    width: 100%;
    /* height: 100%; */
    height: 100%; /* Resta 30px para tener 15px arriba y 15px abajo libres    calc(100% - 150px);*/
    /*background-color: rgba(0, 0, 0, 0.248);*/ /* Ajusta el nivel de transparencia aquí (0.5 = 50% de transparencia) */
    /*background-color: rgba(255, 255, 255, 0.648); *//* Ajusta el nivel de transparencia aquí (0.5 = 50% de transparencia) */
}
/*Logo*/
/*left: calc(95% - 160px);*/ /* calc(95% - 160px) */
.logoDincors {
    position: absolute;
    top: 30px;
    right: calc(95% - 180px);
}
.logoDincors img {
    filter: drop-shadow(0 0 15px rgba(255, 255, 255, 5));
/*        filter: drop-shadow(0 0 15px rgba(255, 255, 255, 1));
*/    }
/*borde externo*/
.box{
    position: relative;
    width: 400px; /*380*/
    height: 560px; /*420*/
    background: rgba(255, 255, 255, 0.778); /*1c1c1c*/
    border-radius: 8px;
    overflow: hidden;
}
/* linea alrededor*/
/*.box::before{
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 400px;
    height: 480px;
    background: linear-gradient(0deg,transparent, transparent, #50c0e2,#50c0e2,#50c0e2);*//*45f3ff verde: 32c474*/
    /*z-index: 1;
    transform-origin: bottom right;
    animation: animate 6s linear infinite;
}
.box::after{
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 400px;
    height: 480px;
    background: linear-gradient(0deg,transparent, transparent, #50c0e2,#50c0e2,#50c0e2);
    z-index: 1;
    transform-origin: bottom right;
    animation: animate 6s linear infinite;
    animation-delay: -3s;
}*/
/*Linea secundaria: ff2770  F98866*/
/*.borderLine{
    position: absolute;
    top: 0;
    inset: 0;
}
.borderLine::before{
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 400px;
    height: 480px;
    background: linear-gradient(0deg,transparent, transparent, #32c474,#32c474,#32c474);
    z-index: 1;
    transform-origin: bottom right;
    animation: animate 6s linear infinite;
    animation-delay: -1.5s;
}
.borderLine::after{
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 400px;
    height: 480px;
    background: linear-gradient(0deg,transparent, transparent, #32c474,#32c474,#32c474);
    z-index: 1;
    transform-origin: bottom right;
    animation: animate 6s linear infinite;
    animation-delay: -4.5s;
}*/

@keyframes animate{
    0%{
        transform: rotate(0deg);

    }
    100%{
        transform: rotate(360deg);

    }
}
.box form {
    position: absolute;
    inset: 4px;
    background: #08080803; /*222*/
    padding: 40px 40px;
    border-radius: 8px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    border: 2px solid #2FAC68;
}
    .box form h2 {
        color: #2FAC68;
        font-weight: 700;
        text-align: center;
        letter-spacing: 0.1em;
    }
.box form .inputBox{
    position: relative;
    width: 100%;
    margin-top: 35px;
}
    .box form .inputBox input {
        position: relative;
        width: 100%;
        padding: 20px 10px 10px;
        background: transparent;
        outline: none;
        border: none;
        box-shadow: none;
        color: #23242a;
        font-size: 1em;
        letter-spacing: 0.05em;
        transition: 0.5s;
        z-index: 10;
    }
.box form .inputBox span{
    position: absolute;
    left: 0;
    padding: 20px 0px 10px;
    pointer-events: none;
    color: #8f8f8f;
    font-size: 1em;
    letter-spacing: 0.05em;
    transition: 0.5s;
}
.box form .inputBox input:valid ~ span {
    color: #000000;
    font-size: 0.75em;
    transform: translateY(-34px);
}
.box form .inputBox input:focus ~ span {
    color: #000000;
    font-size: 0.75em;
    transform: translateY(-34px);
}
.box form .inputBox i {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: #32c47447;
    border-radius: 4px;
    overflow: hidden;
    transition: 0.5s;
    pointer-events: none;
}

.box form .inputBox input:valid ~ i {
    height: 44px;
    background: #2fac683c; /* bfe8d2 */
}
.box form .inputBox input:focus ~ i
{
    height: 44px;
}
.box form .links{
    display: flex;
    justify-content: space-between;
}
.box form .links a {
    margin: 10px 0;
    font-size: 0.75em;
    color: #000000;
    text-decoration: none;
}
.box form .links a:hover,
.box form .links a:nth-child(2) {
    color: #0f0e0e;
}
.box form .boton a {
    border: none;
    outline: none;
    margin: auto;
    padding: 9px 25px;
    background: #2FAC68;
    cursor: pointer;
    font-size: 0.9em;
    border-radius: 4px;
    font-weight: 600;
    width: 200px;
    margin-top: 12px;
    text-align: center;
}
.box form .boton a:hover {
    border: none;
    outline: none;
    padding: 9px 25px;
    background: #32c4748e;
    cursor: pointer;
    font-size: 0.9em;
    border-radius: 4px;
    font-weight: 600;
    width: 200px;
    margin-top: 10px;
}
.box form .boton a:active {
    opacity: 0.8;
}

.boton {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}
.boton a {
    color: white;
    text-decoration: none;
}