.button-dropdown {
    text-decoration: none !important;
    background-color: transparent;
    border: none; /* Opcional: quita el borde si lo deseas */
    color: #007bff; /* Cambia el color del ícono si lo necesitas */
  }

  @keyframes parpadear {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.2;
    }
  }
  
  .parpadeo {
    animation: parpadear 1.5s infinite;
  }