*:not(i) {
    font-size: 14px !important;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

body {
    display: block;
    justify-content: left;
    align-items: left;
    min-height: 100vh;
    /*background-image: url("../img/FondoLogin/4.jpg");*/
    /*background: rgba(35, 36, 42, 0.226);*/ /* 23242a*/
    background-size: cover;
    background-position: left;
}
body::before {
    content: "";
    position: absolute;
    top: 0px; /* 75px */
    left: 0;
    width: 100%;
    /* height: 100%; */
    height: 100%; /* Resta 30px para tener 15px arriba y 15px abajo libres    calc(100% - 150px);*/
    /*background-color: rgba(0, 0, 0, 0.248);*/ /* Ajusta el nivel de transparencia aquí (0.5 = 50% de transparencia) */
    background-color: rgba(255, 255, 255, 0.648); /* Ajusta el nivel de transparencia aquí (0.5 = 50% de transparencia) */
}

/* HEAD */
.din-nav-principal{
    height: 56px;
}
/* #header-layout{
    position: fixed;
} */
#header-layout nav{
    background-color: #2FAC68;/* 2FAD65 */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
}
#din-nav-brand{
    vertical-align: middle;
}
#din-navbar li a{
    color: white;
}

.navbar-toggler{
    color: white !important;
    border: white solid 0.1px !important;
}

/* RUTAS HEAD */
.custom-breadcrumb {
    --bs-breadcrumb-divider: '>'; /* Establece la variable personalizada */
}
.breadcrumb{
    margin: 0px !important;
}
.breadcrumb .active{
    font-weight: 500;
}
.breadcrumb>li>a {
    text-decoration: none;
    color: rgba(33, 37, 41, 0.75);
}
.din-route-link>:hover {
    color: #0d6efd !important;
}
.din-nav-router{
    background-color: #F0F1F0;
}

/* BOTON LISTA */
.din-btn-style{
    padding-left: 20px !important;
    padding-right: 20px !important;
    border-radius: 4px !important;
}

/* TABLA */

.din-table-1 thead tr th{
    background-color: #919191;
    color: rgb(255, 255, 255);
    text-align: center;
    vertical-align: middle;
}

/* paginación */
.active>.page-link, .page-link.active {
    background-color: #198754 !important;
    border-color: #198754 !important;
    color: white !important;
}

.page-link {
    color: #198754 !important;
}

/*  CARD LISTADO */
.din-card-header{
    background: #e4e4e4 !important;
}


/* MODAL */
.modal-content {
    border-radius: 0.6rem !important;
}

.btn-close {
    --bs-btn-close-color: #FFF !important; /* Cambia el color de la "X" a blanco */
    --bs-btn-close-bg: url('/src/Assets/img/cross-svgrepo-com.svg') !important;
    color:white !important;
    /* opacity: 0.4 !important; */
}
.din-input-text-modal{
    background: #e8e8e8 !important;
    border: solid 0.5px #dee2e6 !important;
    /* box-shadow: 0 0 5px rgb(62, 62, 62)e4 !important; */
}


/* RESPONSIVE */
@media (max-width: 500px) {
    .home-section {
        width: 500px !important;
    }
} 

@media (min-width: 320px) {
    .custom-col {
        width: 100% !important; 
        margin-bottom: 5px;
    }
    .custom-col-pagination {
        width: 50% !important; 
    }
}


@media (min-width: 620px) {
    .custom-col {
        width: 50% !important; 
        margin-bottom: 5px;
    }
    .custom-col-pagination { 
        width: 29% !important; 
    }
}
/* @media (min-width: 700px) {
    .custom-col {
        width: 50% !important;
        padding-left: 0px;
        margin-bottom: 5px;
    }
    .custom-col-pagination { 
        width: 32% !important;
    }
} */
@media (min-width: 880px) {
    .custom-col {
        width: 33% !important;
        margin-right: 1px;
    }
    .custom-col-pagination {
        width: 20% !important;
    }
}

@media (min-width: 1024px) {
    .custom-col {
        width: 33% !important;
        margin-right: 1px;
    }
    .custom-col-pagination {
        width: 17% !important;
        margin-right: 1px;
    }
    
}
@media (min-width: 1200px) {
    .custom-col {
        width: 24.9% !important;
        margin-right: 1px;
    }
    .custom-col-pagination {
        width: 14% !important;
        margin-right: 1px;
    }
}
@media (min-width: 1320px) {
    .custom-col {
        width: 19.9% !important;
        margin-right: 1px;
    }
    .custom-col-pagination {
        width: 12% !important;
        margin-right: 1px;
    }
}
/* @media (min-width: 320px) {
    .custom-col {
        width: 100% !important; 
        margin-bottom: 5px;
    }
    .custom-col-pagination {
        width: 100% !important; 
    }
}

@media (max-width: 500px) {
    .home-section {
        width: 500px !important;
    }
} 

@media (min-width: 600px) {
    .custom-col {
        width: 100% !important; 
        margin-bottom: 5px;
    }
    .custom-col-pagination { 
        width: 100% !important; 
    }
}
@media (min-width: 700px) {
    .custom-col {
        width: 50% !important;
        padding-left: 0px;
        margin-bottom: 5px;
    }
    .custom-col-pagination { 
        width: 32% !important;
    }
}
@media (min-width: 850px) {
    .custom-col {
        width: 25% !important;
        margin-right: 1px;
    }
    .custom-col-pagination {
        width: 25% !important;
    }
}
@media (min-width: 1020px) {
    .custom-col {
        width: 19% !important;
        margin-right: 1px;
    }
    .custom-col-pagination { 
        width: 19% !important;
        margin-right: 1px;
    }
}
@media (min-width: 1200px) {
    .custom-col-pagination {
        width: 15% !important;
        margin-right: 1px;
    }
} */

/* RESPONSIVE - TABLA */
@media (max-width: 767px) {
    .din-table-1 th {
        font-size: 9px !important;
    }

    .din-table-1 tr {
      border: 1px solid #dee2e6;
    }
  
    .din-table-1 td {
      font-size: 10px !important;
    }
  
    .din-table-1 td::before {
      font-weight: bold;
      width: 50%; /* Ajustar según tus necesidades */
    }
}

@media (min-width: 767px) {
    .din-table-1 th {
        font-size: 11px !important;
    }
    .din-table-1 td {
        font-size: 11px !important;
    }
}
@media (min-width: 1020px) {
    .din-table-1 th {
        font-size: 12px !important;
    }
    .din-table-1 td {
        font-size: 12px !important;
    }
}
@media (min-width: 1200px) {
    .din-table-1 th {
        font-size: 14px !important;
    }
    .din-table-1 td {
        font-size: 14px !important;
    }
}

/* Cuadre caja */
#TabsCaja .nav-tabs  .nav-link {
    text-decoration: none;
    color: black !important;
}
#TabsCaja .nav-tabs  .nav-link.active {
    color: #198754 !important;
    font-weight: bold;
}